import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsFillGearFill } from 'react-icons/bs';
import { BiSolidDashboard } from "react-icons/bi";
import { FaParking } from "react-icons/fa";
import { MdAccountCircle, MdStars, MdPermContactCalendar, MdOutlineMap } from "react-icons/md";
import { FaNewspaper } from "react-icons/fa6";
import { IoLogOut } from "react-icons/io5";
import { HiUserGroup, HiHome } from "react-icons/hi";
import { PiNewspaperClippingFill } from "react-icons/pi";
import { GoFileDirectoryFill } from "react-icons/go";
import { HiMiniUserGroup } from "react-icons/hi2";
import Cookies from 'js-cookie';

const Sidebar = () => {
    const [currentPath, setcurrentPath] = useState();
    const SidebarIcon = ({ url, icon, text }) => {
        const currentPath = window.location.pathname;
        setcurrentPath(currentPath);
        var isActive = currentPath === url;
        const height = text === "Website" ? "hover:h-[208px]" : "hover:h-[205px]";

        const colors = {
            "Dashboard": "bg-yellow-200",
            "Directory": "bg-blue-300",
            "Map": "bg-orange-300",
            "Settings": "bg-gray-100",
            "Announcements": "bg-blue-300",
            "Help": "bg-orange-200",
            "Log Out": "bg-red-300"
        }

        const hoverColors = {
            "Dashboard": "hover:bg-yellow-100",
            "Directory": "hover:bg-blue-200",
            "Map": "hover:bg-orange-200",
            "Settings": "hover:bg-gray-500",
            "Announcements": "hover:bg-blue-200",
            "Help": "hover:bg-orange-200",
            "Log Out": "hover:bg-red-300"
        }

        const folder = {
            "Website": {
                "Home": <HiHome size={20} />,
                "About Us": <HiMiniUserGroup size={20} />,
                "Features": <MdStars size={20} />,
                "Contact Us": <MdPermContactCalendar size={20} />,
            },
            "Settings": {
                "My Account": <MdAccountCircle size={20} />,
                "Users": <HiUserGroup size={20} />,
                "Lots": <FaParking size={20} />,
                "Permits": <PiNewspaperClippingFill size={20} />,
            }
        }

        const handleLogOut = () => {
            sessionStorage.clear();
            localStorage.clear();
            Cookies.remove("accessToken");
            window.location.href = "/login";
        }

        if (text === "Website" || text === "Settings") {
            var newUrl = "";
            if (text === "Website") {
                newUrl = "/Website/" + Object.keys(folder[text])[0].toLowerCase().replace(/\s/g, '-');
            } else {
                newUrl = "/settings/" + Object.keys(folder[text])[0].toLowerCase().replace(/\s/g, '-');
            }

            isActive = currentPath === newUrl;

            return (
                <div className={`relative items-center justify-center rounded-3xl w-16 h-16 mt-2 mb-2 m-auto ${isActive ? `${height}` : `${hoverColors[text]} hover:rounded-[10px] ${height}`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : `bg-gray-300 ${text === "Settings" ? "text-gray-500 hover:text-gray-300 delay-0" : "text-gray-500"}`}`}>
                    <div className={`${isActive ? "flex justify-center align-middle mt-[15px]" : `flex justify-center align-middle mt-[15px]`}`}>
                        {icon}
                    </div>
                    <div className={`group-hover:flex group-hover:flex-col group-hover:justify-center group-hover:align-middle cursor-pointer hidden w-auto p-2 group-hover:ml-[-2px] min-w-max text-s ${text === "Settings" ? "text-gray-300" : "text-gray-500"} font-bold transition-all duration-100 origin-left group-hover:opacity-100  ${isActive ? null : "opacity-0"}`}>
                        {Object.entries(folder[text]).map(([key, value], index) => (
                            <Link to={`/${text}/` + key.toLowerCase().replace(/\s/g, '-')} key={index} onClick={() => setcurrentPath(text)} className={`py-2 px-4 ${text === "Website" ? "hover:text-orange-200 hover:bg-gray-600" : "hover:bg-gray-300 hover:text-gray-600"} rounded-md transition-all duration-100 ease-in-out group/sub`}>
                                {value}
                                <div className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-xs text-white font-bold transition-all duration-100 scale-0 origin-left group-hover/sub:scale-100" style={{ top: `${50 + (index * 35)}px` }}>
                                    {key}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            );
        } else if (text === "Log Out") {
            return (
                <div className={`relative flex items-center justify-center cursor-pointer rounded-3xl w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : "bg-gray-300 text-gray-500"}`} onClick={handleLogOut}>
                    <div className={`${isActive ? null : `transition-all duration-100 ease-in-out`}`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
                        {text}
                    </span>
                </div>
            );
        } else {
            return (
                <Link to={url} onClick={() => setcurrentPath(text)} className={`relative flex items-center justify-center cursor-pointer rounded-3xl w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : "bg-gray-300 text-gray-500"}`}>
                    <div className={`${isActive ? null : `transition-all duration-100 ease-in-out`}`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
                        {text}
                    </span>
                </Link>
            );
        }
    }

    return (
        <div className="flex fixed top-0 left-0 h-screen w-20 m-0 flex-col min-w-20 bg-transparent text-white shadow-xl z-50 backdrop-blur-md">
            <div className='fixed left-2 flex flex-col bg-transparent'>
                <SidebarIcon url={"/"} icon={<BiSolidDashboard size="32" />} text='Dashboard' />
                <SidebarIcon url={"/directory"} icon={<GoFileDirectoryFill size="32" />} text='Directory' />
                <SidebarIcon url={"/map"} icon={<MdOutlineMap size="35" />} text='Map' />
                <SidebarIcon url={"/announcements"} icon={<FaNewspaper size="35" />} text='Announcements' />
                {/* <SidebarIcon url={"/website"} icon={<CgWebsite size="35" />} text='Website' /> */}
                <hr className='flex justify-center align-middle ml-2 w-12 border-1 border-gray-400 mt-2 mb-2'></hr>
                <SidebarIcon icon={<BsFillGearFill size="32" />} text='Settings' />
                {/* <SidebarIcon url={"/map"} icon={<FaNewspaper size="32" />} text="What's New" /> */}
                {/* <SidebarIcon url={"/help"} icon={<MdHelp size="32" />} text="Help" /> */}
            </div>
            <div className='fixed flex align-bottom justify-end bottom-0 left-2'>
                <SidebarIcon url={"/login"} icon={<IoLogOut size="32" />} text="Log Out" />
            </div>
        </div>
    );
}



export default Sidebar;